import React from 'react'
import * as styles from './TextCard.module.scss'

export default function TextCard({ children, darkTheme = false }) {
  return (
    <li className={`${styles.textCard} ${darkTheme && styles['dark']}`}>
      {children}
    </li>
  )
}

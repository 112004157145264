import React from 'react'

import GeneralPageLayout from '../components/Layouts/GeneralPage'
import SectionContainer from '../components/UI/Containers/SectionContainer'
import SectionHeader from '../components/UI/SectionHeader/SectionHeader'
import OutlineButton from '../components/UI/Buttons/OutlineButton/OutlineButton'
import HalfSplit from '../components/Layouts/HalfSplit/HalfSplit'
import MainTextBlock from '../components/UI/MainTextBlock/MainTextBlock'
import SuppliersImg from './../images/suppliers-businessMeeting.webp'
import { TextCardGroup } from '../components/UI/Containers/TextCard/TextCardGroup'
import CodeConduct from './../pdfs/Precise_Supplier_Code_of_Conduct_rev2.pdf'

const SuppliersPage = () => {
  const pageInfo = {
    pageType: 'interior',
    title1: '',
    title2: 'Suppliers',
    breadcrumbs: [],
    ctaButton: false,
    ctaButtonData: {
      text: '',
      url: '',
    },
  }

  return (
    <GeneralPageLayout pageData={pageInfo}>
      <div className="content">
        <SectionContainer
          id="suppliersIntro"
          color="white"
          type="contained"
          classes={['']}
        >
          <p className={`basicTextMd narrowContentCol`}>
            Precise embraces partnership with suppliers and consultants of all
            types that demonstrate the ability to add value, provide high
            quality goods and /or services, are competitively priced, reliable,
            and aligned with our strategic business model.
          </p>
          <br />
          <p className={`basicTextMd narrowContentCol sectionBtmM`}>
            To enhance your opportunity for consideration as a supplier to
            Precise please fill out our Supplier Questionnaire and submit via
            the below button. Your company information will be maintained in a
            database and made available to Precise Operation and Business
            Development staff for potential procurement activities.
          </p>
        </SectionContainer>
        <SectionContainer
          id=""
          topBorder={true}
          btmBorder={true}
          color="navy"
          type="fullWidth"
        >
          <SectionHeader
            classes={['btmMargin-48']}
            color="white"
            alignment="center"
            size="med"
          >
            Our Supplier Diversity Goal is To <br />
            <span>
              Maximize Opportunities for <br /> Small Businesses
            </span>
          </SectionHeader>
          <section className="inlineChildren_smMobile pride__aspects">
            <TextCardGroup darkTheme={true}>
              <TextCardGroup.Header>
                What small businesses does Supplier Diversity include?
              </TextCardGroup.Header>
              <TextCardGroup.TextCardList>
                <TextCardGroup.TextCard>
                  Small Business (SB)
                </TextCardGroup.TextCard>
                <TextCardGroup.TextCard>
                  Small Disadvantaged Business (SDB)
                </TextCardGroup.TextCard>
                <TextCardGroup.TextCard>
                  Women-Owned Small Business (WOSB)
                </TextCardGroup.TextCard>
                <TextCardGroup.TextCard>
                  Historically Underutilized Business Zones (HUBZone)
                </TextCardGroup.TextCard>
                <TextCardGroup.TextCard>
                  Veteran-Owned Small Business (VOSB)
                </TextCardGroup.TextCard>
                <TextCardGroup.TextCard>
                  Service-Disabled Veteran-Owned Small Business (SDVOSB)
                </TextCardGroup.TextCard>
                <TextCardGroup.TextCard>
                  Historically Black Colleges/Universities and Minority
                  Institutions (HBCUs/Mis)
                </TextCardGroup.TextCard>
                <TextCardGroup.TextCard>
                  Alaska Native Corporations (ANCs) and Indian Tribes
                </TextCardGroup.TextCard>
              </TextCardGroup.TextCardList>
            </TextCardGroup>
          </section>
        </SectionContainer>

        <SectionContainer
          id=""
          color="white"
          type="pressedEdgeLeft"
          classes={['topMargin-124', 'clearTopMarginMobile']}
        >
          <HalfSplit
            imgOrientation="vert"
            imageObj={
              <img
                src={SuppliersImg}
                title="Suppliers warehouse"
                alt="fork lift being operated in a light warehouse"
                style={{ objectPosition: '100% 30%' }}
              />
            }
            contentObj={
              <section>
                <SectionHeader
                  color="navy"
                  alignment="left"
                  size="med"
                  boldBreak={true}
                >
                  We Seek to Promote <span>Consistent Inclusion</span>
                </SectionHeader>
                <MainTextBlock
                  buttonData={{
                    type: `gray`,
                    url: CodeConduct,
                    text: 'Supplier Code of Conduct',
                    location: `external`,
                    icon: `bi:cloud-download`,
                    openSeperate: true,
                  }}
                >
                  <p>
                    We seek to promote consistent inclusion of small and diverse
                    businesses in all our purchasing activities. We champion the
                    development of a diverse supplier base because we know the
                    best solutions are born from differences in thought,
                    perspective, background, and experience. We strive to
                    provide opportunities to diverse suppliers that satisfy our
                    procurement and contractual standards and support our
                    customers in achieving their diversity and small business
                    objectives.
                  </p>
                </MainTextBlock>
              </section>
            }
          />
        </SectionContainer>

        <SectionContainer id="" color="gray" type="fullWidth">
          <SectionHeader
            color="navy"
            size="med"
            trim={true}
            boldBreak={true}
            alignment="center"
            classes={['darkTag']}
          >
            Interested in Becoming
            <span>Our Supplier?</span>
          </SectionHeader>
          <div className="inlineChildren topMargin-32 justifyChildrenCenter narrowContentCol">
            <OutlineButton
              buttonData={{
                text: 'Submit Questionnaire',
                url: 'https://app.smartsheetgov.com/b/form/8859c4be906b4bdfbe0d4bccca0a5d8b',
                icon: 'akar-icons:send',
                location: 'external',
                openSeperate: true,
              }}
              type="colored"
            />
          </div>

          <p className="footnote simple narrowContentCol">
            Submitting a questionnaire does not guarantee your company will
            become a supplier with Precise, but it does provide enhanced
            visibility of your firm's capabilities to our program and
            procurement teams. Should Precise find your company's capabilities
            in line with one or more of our current opportunities we will reach
            out to engage your identified point of contact. If you are a small
            business Precise may also use this information to send invitations
            for Precise small business outreach and assistance initiatives.
          </p>
        </SectionContainer>
      </div>
    </GeneralPageLayout>
  )
}

export default SuppliersPage

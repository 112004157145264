import React from 'react'
import * as styles from './TextCard.module.scss'
import TextCard from './TextCard'

function TextCardGroup({ children, align = 'center', darkTheme = false }) {
  return (
    <section
      className={`${styles.textCardGroup} ${styles[`${align}`]} ${
        darkTheme && styles['dark']
      }`}
    >
      {children}
    </section>
  )
}

function TextCardList({ children }) {
  return <ul className={`${styles.textCardList}`}>{children}</ul>
}

function TextCardHeader({ children }) {
  return <em className={styles.textCardList_header}>{children}</em>
}

TextCardGroup.TextCardList = TextCardList
TextCardGroup.TextCard = TextCard
TextCardGroup.Header = TextCardHeader

export { TextCardGroup }
